import {useMemo} from 'react';

export const useBonus = () => {

	const calculateBonus = (sum: string | number) => {

    const start = new Date(1735272000000)
    const now = new Date()
    const end = new Date(1735876800000)

    if (now > start && now < end) {
		const sumAsNumber = Number(sum)

		if (sumAsNumber <= 2024) {
			return Math.ceil(sumAsNumber + sumAsNumber * 0.5);
		}
		if (sumAsNumber >= 2025) {
			return Math.ceil(sumAsNumber * 2);
		}
			// return Number(sum) * 2
    }

		const numberSum = Number(sum)
		if (numberSum < 0) {
			return 0
		}
		let localSum = numberSum
		if (numberSum >= 100000) {
			const bonus = numberSum / 100 * 50
			localSum += bonus
			return localSum
		}
		if (numberSum >= 15000) {
			const bonus = numberSum / 100 * 25
			localSum += bonus
			return localSum
		}
		if (numberSum >= 5000) {
			const bonus = numberSum / 100 * 15
			localSum += bonus
			return localSum
		}
		return localSum
	}

	return [ calculateBonus ]
}
